import { marked } from 'marked';

marked.setOptions({
  gfm: true,
});

export const openMarkdownAsPdf = (
  markdown: string,
  additionalPdfContent: string,
  logo: string = '/assets/img/zeta-logo-full-black.svg'
) => {
  const printContent = () => {
    const printWindow = window.open('', '', 'width=600,height=400');
    if (!printWindow) {
      return;
    }
    const title = markdown.split('\n')[0].replace(/^#+\s*/, '');
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString();
    const created = `<p class="date">${currentDate} ${currentTime} ${additionalPdfContent}</p>`;
    const updatedMarkdown = `${created}\n\n${markdown}`;

    printWindow.document.write(`
        <html lang="en">
          <head>
            <title>${title}</title>
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap" rel="stylesheet">
            <style>
            body {
              font-family: 'Inter', sans-serif;
              padding: 20px;
              color: #333;
            }
            h1 {
              color: #005d83;
            }
            .header {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            }
            .date {
              font-size: 12px;
              color: #666;
            }
            .title, h1 {
              color: #005d83;
            }
            .header img {
              height: 30px;
            }
            /* Customize the printed content */
            @media print {
              body {
                font-size: 16px;
              }
              h1 {
                color: #005d83;
              }
              /* Additional print styles */
            }
          </style>
        </head>
        <body>
         <div class="header">
              <img src="${logo}" alt="Logo" />
            </div>
          <div class="content">
            ${marked.parse(updatedMarkdown)}
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();

    printWindow.onload = () => {
      printWindow.focus();
      printWindow.print();
    };
  };

  printContent();
};
