import { uniq } from 'lodash';

import {
  AuthenticationSettings,
  BotConfiguration,
  ChatFeatureConfiguration,
  ChatPage,
  ClientIndexSettings,
  ClientIndexSettingsTypeEnum,
  FeatureSettings,
  PDFViewer,
  QAConfiguration,
  SearchFilterSettings,
  SearchSortingSettings,
  TagsSection,
  TenantSettings as TenantSettingsResponse,
  WhiteLabelPagesSettings,
  WhiteLabelSettings,
  WidgetsConfiguration,
} from '@zarn/vendor/dist/search';

import { RetrievalMethodEnum, RetrievalUnitEnum } from 'common/enums';
import { Optional } from 'common/utils/assert';
import { PartialRecord } from 'common/utils/types.helpers';

import { deserializeDisplayConfiguration } from './displayConfiguration.utils';
import {
  deserializeDynamicFilters,
  isNotDynamicFilter,
} from './dynamicFilters.utils';
import {
  BotConfiguration as BotsConfigurationOutput,
  BotType,
  BotTypeMessage,
  BotTypeMessageWithMessage,
  ChatSettings,
  FeaturesSettings,
  Oidc,
  RetrievalMethodFilter,
  SearchFilters,
  SearchSortingSettings as SearchSorting,
  Tab,
  Tabs,
  TenantSettings,
  Widgets,
} from './tenantSettingsApi.types';
import {
  InfoPage,
  InfoPagesKeys,
  WhitelabelSettings as WhitelabelSettingsOutput,
} from './WhitelabelSettings.types';
import { deserializeWidgetsLayout } from './WidgetLayout/WidgetLayout.utils';

const deserializeFilterEnabled = (
  filterName: string,
  filtersArr: Array<SearchFilterSettings>
) => {
  const isFilterInArray = filtersArr.find(
    ({ field_name }) => field_name === filterName
  );

  return !!isFilterInArray;
};

const deserializeSearchFilters = (
  filters: ClientIndexSettings[] | null
): SearchFilters => {
  if (!filters) {
    return {
      codeFilterEnabled: false,
      countryFilterEnabled: false,
      dateFilterEnabled: false,
      organizationFilterEnabled: false,
      ownerFilterEnabled: false,
      sourceFilterEnabled: false,
      tagFilterEnabled: false,
      typeFilterEnabled: false,
    };
  }
  const defaultIndex = filters.filter(
    (el) => el.type === ClientIndexSettingsTypeEnum.Internal
  );

  const filtersArr = (defaultIndex[0].search_filters_config ?? []).filter(
    isNotDynamicFilter
  );

  return {
    codeFilterEnabled: deserializeFilterEnabled(
      'metadata.github_metrics',
      filtersArr
    ),
    countryFilterEnabled: deserializeFilterEnabled(
      'metadata.DCMI.creator.location.country',
      filtersArr
    ),
    dateFilterEnabled: deserializeFilterEnabled(
      'metadata.DCMI.date',
      filtersArr
    ),
    organizationFilterEnabled: deserializeFilterEnabled(
      'metadata.DCMI.creator.organization.name',
      filtersArr
    ),
    ownerFilterEnabled: deserializeFilterEnabled(
      'allow_access_rights',
      filtersArr
    ),
    sourceFilterEnabled: deserializeFilterEnabled(
      'metadata.DCMI.source',
      filtersArr
    ),
    tagFilterEnabled: deserializeFilterEnabled(
      'features.tags.tag_id',
      filtersArr
    ),
    typeFilterEnabled: deserializeFilterEnabled('document_type', filtersArr),
  };
};

const deserializeWidgets = (widgets: WidgetsConfiguration | null): Widgets => ({
  analyticsWidgetEnabled: !!widgets?.analytics,
  expertSearchWidgetEnabled: !!widgets?.expert_search,
  findAuthoredByWidgetEnabled: !!widgets?.find_authored_by,
  findSimilarDocumentWidgetEnabled: !!widgets?.find_similar_document,
  layout: deserializeWidgetsLayout(widgets),
  qaWidgetEnabled: !!widgets?.qa,
  queryAnalysisWidgetEnabled: !!widgets?.query_analysis,
  searchResultsWidgetEnabled: !!widgets?.search_results,
  vosViewerWidgetEnabled: !!widgets?.vos_viewer,
});

const deserializeDefaultTab = (tab: ClientIndexSettings): Tab => ({
  enabled: !!tab,
  tabTitle: tab.title ?? '',
});

const deserializeFederatedTab = (
  tab: ClientIndexSettings[],
  identifierName: string
): Tab => {
  const selectedTab = tab.find(
    (el) => el.search_engine_identifier === identifierName
  );

  return {
    enabled: !!selectedTab,
    tabTitle: selectedTab?.title ?? '',
  };
};

const deserializeTabs = (indexes: ClientIndexSettings[] | null): Tabs => {
  if (!indexes) {
    return {
      default: {
        enabled: false,
        tabTitle: '',
      },
      google: {
        enabled: false,
        tabTitle: '',
      },
      googleScholar: {
        enabled: false,
        tabTitle: '',
      },
    };
  }
  const defaultIndex = indexes.filter(
    (el) => el.type === ClientIndexSettingsTypeEnum.Internal
  );
  const federatedIndex = indexes.filter(
    (el) => el.type === ClientIndexSettingsTypeEnum.Federated
  );

  return {
    default: deserializeDefaultTab(defaultIndex[0]),
    google: deserializeFederatedTab(federatedIndex, 'google'),
    googleScholar: deserializeFederatedTab(federatedIndex, 'google_scholar'),
  };
};

export const deserializeOidc = (oidc: AuthenticationSettings): Oidc => ({
  authority:
    oidc.protocol === 'oidc' && oidc.protocol_settings.oidc?.issuer
      ? oidc.protocol_settings.oidc?.issuer
      : '',
  clientId:
    oidc.protocol === 'oidc' && oidc.protocol_settings.oidc?.client_id
      ? oidc.protocol_settings.oidc?.client_id
      : '',
  enabled: oidc.protocol === 'oidc' ?? false,
});

const deserializeBotsConfig = (
  identifier: BotType,
  botConfigs: Array<BotConfiguration>
) => {
  const botConfig = botConfigs.find(
    ({ bot_identifier }) => bot_identifier == identifier
  );
  if (!botConfig) {
    return;
  }

  const botConfiguration: BotsConfigurationOutput = {
    botParams: botConfig.bot_params,
    identifier,
    label: botConfig.display_name,
    tenantBotType: botConfig.bot_type,
  };

  if (botConfig.bot_params?.display_description) {
    botConfiguration.description = botConfig.bot_params.display_description;
  }

  if (botConfig.bot_params?.display_icon) {
    botConfiguration.icon = botConfig.bot_params.display_icon;
  }

  return botConfiguration;
};

const getIds = (chats: Array<ChatFeatureConfiguration | undefined>) =>
  uniq(
    chats.reduce((acc, chat) => {
      if (!chat) {
        return acc;
      }

      return [
        ...acc,
        chat.default_bot_identifier,
        ...(chat.extra_bot_identifiers ?? []),
      ];
    }, [] as string[])
  );

const deserializeBotsConfiguration = (
  botConfigs: Array<BotConfiguration> | null,
  chats: Array<ChatFeatureConfiguration | undefined>
): PartialRecord<BotType, BotsConfigurationOutput> => {
  if (!botConfigs) {
    return {};
  }

  const identifiers = getIds(chats);

  return identifiers.reduce((acc, identifier) => {
    if (!identifier) {
      return acc;
    }

    const chatConfig = deserializeBotsConfig(identifier, botConfigs);

    return { ...acc, [identifier]: chatConfig };
  }, {} as PartialRecord<BotType, BotsConfigurationOutput>);
};

const mapBotTypeIdentifier = (
  botType: string | undefined,
  botConfig: Array<BotConfiguration> | null
): BotType | undefined => {
  const config = botConfig?.find(
    ({ bot_identifier }) => bot_identifier === botType
  );
  return config?.bot_type ?? botType;
};

export const isBotsConfigurationOutput = (
  config: Optional<BotsConfigurationOutput>
): config is BotsConfigurationOutput => !!config;

const getBotTypesConfiguration = (
  botsConfiguration: PartialRecord<BotType, BotsConfigurationOutput>
): PartialRecord<BotType, BotsConfigurationOutput> =>
  Object.fromEntries(
    Object.values(botsConfiguration)
      .filter(isBotsConfigurationOutput)
      .map((config) => [config.tenantBotType, config])
  );

const getChatConfig = (
  chat: Optional<ChatFeatureConfiguration>,
  botConfig: Array<BotConfiguration> | null
) => {
  const defaultBotType = mapBotTypeIdentifier(
    chat?.default_bot_identifier,
    botConfig
  );
  if (!defaultBotType) {
    return undefined;
  }
  return {
    defaultBotType,
    extraBotTypes: (chat?.extra_bot_identifiers ?? [])
      .map((bot: string) => mapBotTypeIdentifier(bot, botConfig))
      .filter((bot): bot is BotType => !!bot),
  };
};

export const deserializeChat = (
  botConfig: Array<BotConfiguration> | null,
  chatPDF: PDFViewer | null,
  chatTag: TagsSection | null,
  chatQA: QAConfiguration | null,
  chatPageConfig: ChatPage | null
): ChatSettings => {
  const botsConfiguration = deserializeBotsConfiguration(botConfig, [
    chatPDF?.chat,
    chatTag?.chat,
    chatQA?.chat,
    chatPageConfig?.chat,
  ]);

  return {
    botTypesConfiguration: getBotTypesConfiguration(botsConfiguration),
    chatPageConfig: getChatConfig(chatPageConfig?.chat, botConfig),
    pdfViewerPageConfig: getChatConfig(chatPDF?.chat, botConfig),
    qaWidgetConfig: getChatConfig(chatQA?.chat, botConfig),
    tagPageConfig: getChatConfig(chatTag?.chat, botConfig),
  };
};

const deserializePageContent = (
  pageContent: WhiteLabelPagesSettings,
  pageKey: InfoPagesKeys
): PartialRecord<InfoPagesKeys, InfoPage> => {
  const pageContentElement = pageContent[pageKey];
  return {
    ...(pageContent && pageContentElement
      ? {
          [pageKey]: {
            title: pageContentElement.title,
            url: pageContentElement.url,
          },
        }
      : {}),
  };
};

const deserializeWhiteLabelPages = (
  pages: WhiteLabelPagesSettings
): PartialRecord<InfoPagesKeys, InfoPage> => ({
  ...deserializePageContent(pages, 'about'),
  ...deserializePageContent(pages, 'features'),
  ...deserializePageContent(pages, 'contact'),
});

const deserializeOptionalElement = <T>(element: T, label: string) => ({
  ...(element ? { [label]: element } : {}),
});

export const deserializeWhiteLabel = (
  whitelabel: WhiteLabelSettings
): WhitelabelSettingsOutput => ({
  ...(whitelabel?.logo
    ? {
        logo: {
          mobile: whitelabel.logo.mobile,
          web: whitelabel.logo.web,
        },
      }
    : {}),
  ...(whitelabel.header
    ? {
        header: {
          ...deserializeOptionalElement(
            whitelabel.header.background,
            'background'
          ),
          ...deserializeOptionalElement(
            whitelabel.header.icon_color,
            'iconColor'
          ),
          ...deserializeOptionalElement(
            whitelabel.header.font_color,
            'fontColor'
          ),
        },
      }
    : {}),
  ...(whitelabel.search_bar
    ? {
        searchBar: {
          ...deserializeOptionalElement(
            whitelabel.search_bar.background,
            'background'
          ),
        },
      }
    : {}),
  ...(whitelabel.icons
    ? {
        icons: {
          ...deserializeOptionalElement(
            whitelabel.icons.default_color,
            'defaultColor'
          ),
          ...deserializeOptionalElement(
            whitelabel.icons.highlight,
            'highlight'
          ),
        },
      }
    : {}),
  ...(whitelabel.main_content
    ? {
        mainContent: {
          ...deserializeOptionalElement(
            whitelabel.main_content.background,
            'background'
          ),
        },
      }
    : {}),
  ...(whitelabel.pages
    ? { pages: deserializeWhiteLabelPages(whitelabel.pages) }
    : {}),
  colors: {
    primary: whitelabel.colors?.primary,
    secondary: whitelabel.colors?.secondary,
  },
  favicon: whitelabel.favicon,
  footer: whitelabel.footer,
  tags: {
    defaultChipColor: whitelabel.tags?.default_chip_color,
  },
  ...(whitelabel?.app?.name ? { title: whitelabel.app.name } : {}),
});

export const deserializeSearchSorting = (
  searchOptions: SearchSortingSettings[]
): SearchSorting => {
  const chunkSearchOptions = searchOptions
    .filter((el) => el.retrieval_unit === 'chunk' || !el.retrieval_unit)
    .map((el) => ({
      title: el.display_name,
      urlParam: el.url_param,
      value: el.field_name,
    }));
  const documentSearchOptions = searchOptions
    .filter((el) => el.retrieval_unit === 'document' || !el.retrieval_unit)
    .map((el) => ({
      title: el.display_name,
      urlParam: el.url_param,
      value: el.field_name,
    }));

  return {
    ...(chunkSearchOptions ? { chunk: chunkSearchOptions } : []),
    document: documentSearchOptions,
  };
};

const deserializeFeatures = (
  featuresSettings: FeatureSettings
): FeaturesSettings => ({
  ...(featuresSettings.people
    ? {
        people: {
          status: featuresSettings.people.status ?? 'hidden',
        },
      }
    : {}),
  ...(featuresSettings.recommendations
    ? {
        recommendations: {
          status: featuresSettings.recommendations.status ?? 'hidden',
        },
      }
    : {}),
  ...(featuresSettings.document_uploads
    ? {
        documentUploads: {
          status: featuresSettings.document_uploads.status ?? 'hidden',
        },
      }
    : {}),
});

const deserializeDefaultRetrievalUnit = (
  index: ClientIndexSettings
): RetrievalUnitEnum | undefined => {
  if (!index) return undefined;

  if (index.retrieval_unit_config?.default_retrieval_unit) {
    const retrievalUnit = index.retrieval_unit_config.default_retrieval_unit;
    const capitalizedRetrievalUnit =
      retrievalUnit.charAt(0).toUpperCase() + retrievalUnit.slice(1);

    if (capitalizedRetrievalUnit in RetrievalUnitEnum) {
      return RetrievalUnitEnum[
        capitalizedRetrievalUnit as keyof typeof RetrievalUnitEnum
      ];
    }
  }

  return undefined;
};

const getRetrievalMethodEnum = (
  value: string
): RetrievalMethodEnum | undefined => {
  switch (value) {
    case 'mixed':
      return RetrievalMethodEnum.Hybrid;
    case 'keyword':
      return RetrievalMethodEnum.Keyword;
    case 'knn':
      return RetrievalMethodEnum.Knn;
    default:
      return undefined;
  }
};

const deserializeDefaultRetrievalMethod = (
  index: ClientIndexSettings
): RetrievalMethodFilter | undefined => {
  if (!index?.retrieval_method_config) {
    return undefined;
  }

  const config = index.retrieval_method_config;
  const defaultValue = config.default.value;

  if (!defaultValue) return undefined;

  const defaultRetrievalMethodEnum = getRetrievalMethodEnum(defaultValue);

  if (!defaultRetrievalMethodEnum) return undefined;

  const retrievalMethodFilter: RetrievalMethodFilter = {
    default: defaultRetrievalMethodEnum,
  };

  if (config.status === 'hidden') {
    retrievalMethodFilter.hidden = true;
  }

  if (config.options?.length) {
    retrievalMethodFilter.options = config.options
      .map((option) => ({
        label: option.label,
        value: getRetrievalMethodEnum(option.value),
      }))
      .filter(
        (option): option is { label: string; value: RetrievalMethodEnum } =>
          option.value !== undefined
      );
  }

  return retrievalMethodFilter;
};

export const deserializeTenantSettings = (
  settings: TenantSettingsResponse,
  indexCluster?: string | null
): TenantSettings => {
  const defaultIndex = settings.client_settings?.indexes?.[0];
  const index = indexCluster
    ? settings.client_settings?.indexes?.find(
        ({ index_id }) => index_id && indexCluster.includes(index_id)
      )
    : defaultIndex ?? defaultIndex;

  const defaultRetrievalUnit = index
    ? deserializeDefaultRetrievalUnit(index)
    : undefined;

  const defaultRetrievalMethod = index
    ? deserializeDefaultRetrievalMethod(index)
    : undefined;

  return {
    chat: deserializeChat(
      settings.client_settings?.bot_configurations ?? null,
      settings.client_settings?.pdf_viewer ?? null,
      settings.client_settings?.tags_section ?? null,
      settings.client_settings?.widgets?.qa ?? null,
      settings.client_settings?.chat_page ?? null
    ),
    displayConfiguration: deserializeDisplayConfiguration(
      index?.display_configuration ?? null
    ),
    document: {
      ...deserializeOptionalElement(
        settings.client_settings?.document_card?.sharing_options,
        'sharing'
      ),
      ...deserializeOptionalElement(
        settings.client_settings?.document_card?.export_options,
        'exports'
      ),
    },
    features: settings.features ? deserializeFeatures(settings.features) : {},
    indexCluster: indexCluster ?? '',
    note: {
      ...deserializeOptionalElement(
        settings.client_settings?.note_card?.sharing_options,
        'sharing'
      ),
      ...deserializeOptionalElement(
        settings.client_settings?.note_card?.export_options,
        'exports'
      ),
    },
    oidc: deserializeOidc(settings.authentication_settings),
    searchFilters: deserializeSearchFilters(
      settings.client_settings?.indexes ?? null
    ),
    searchFiltersDynamic: deserializeDynamicFilters(
      index?.search_filters_config ?? []
    ),
    searchFiltersInitialValues: {
      ...(defaultRetrievalUnit ? { retrievalUnit: defaultRetrievalUnit } : {}),
      ...(defaultRetrievalMethod
        ? { retrievalMethod: defaultRetrievalMethod }
        : {}),
    },
    tabs: deserializeTabs(settings.client_settings?.indexes ?? null),

    tag: {
      ...deserializeOptionalElement(
        settings.client_settings?.tags_section?.sharing_options,
        'sharing'
      ),
      ...deserializeOptionalElement(
        settings.client_settings?.tags_section?.export_options,
        'exports'
      ),
    },

    widgets: deserializeWidgets(settings.client_settings?.widgets ?? null),
    ...(settings.client_settings?.indexes &&
    settings.client_settings?.indexes[0].search_sorting_config
      ? {
          searchSorting: deserializeSearchSorting(
            settings.client_settings?.indexes[0].search_sorting_config
          ),
        }
      : {}),
    ...(settings.client_settings?.white_label_settings
      ? {
          whitelabel: deserializeWhiteLabel(
            settings.client_settings.white_label_settings
          ),
        }
      : {}),
  };
};

export const isBotTypeMessageWithMessage = (
  message: BotTypeMessage
): message is BotTypeMessageWithMessage => {
  return (message as BotTypeMessageWithMessage).message !== undefined;
};
