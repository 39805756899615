import { makeHash } from './crypto';

export class Cache {
  private cache: Map<string, any>;

  constructor() {
    this.cache = new Map();
  }

  static async getKey(data: any) {
    return await makeHash(JSON.stringify(data));
  }

  public async set(keyData: any, value: any) {
    const key = await Cache.getKey(keyData);
    this.cache.set(key, value);
  }

  public async get(keyData: any) {
    const key = await Cache.getKey(keyData);
    return this.cache.get(key);
  }

  public async isExist(keyData: any) {
    const key = await Cache.getKey(keyData);
    return this.cache.has(key);
  }

  public clear(): void {
    this.cache.clear();
  }
}
